// methods
import {
  getClassLessonList,
  delClassLesson,
  getCurrentYear,
  getTeachList,
  getGradeList,
  getClassList,
  getLessonList,
  editGrade,
  addGrade,
  getTeacherTable,
} from '@/api/basic';

// components
import { Select, Option, Form, FormItem, Input } from 'element-ui';
import basicHeader from '@/components/backStageHeader';
import searchInput from '@/components/searchInput';
import teachDialog from './components/teachDialog';
import ETable from '@/components/table/index.vue';
import OperationBtns from '@components/table/components/operationBtns.vue';
import Dialog from '@components/dialog/dialog.vue';
import EButton from '@components/button/index.vue';
import CascaderHeader from '@components/table/components/cascaderHeader.vue';

export default {
  name: 'teach',

  components: {
    Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    basicHeader,
    searchInput,
    teachDialog,
    ETable,
    EButton,
  },

  data() {
    return {
      loading: false,
      listData: {
        list: [],
        pageSize: 10,
        pageIndex: 1,
        total: 0,
      },
      searchKey: '', // 搜索关键字
      gradeId: '-1', // 年级ID
      gradeList: [], // 年级列表
      classId: '-1', // 班级ID
      classList: [
        {
          classId: '-1',
          className: '全部',
        },
      ],
      lessonList: [], // 学科数据
      teacherList: [], // 教师数据
      isEdit: false, // 是否编辑模式
      visible: false,

      formData: {
        gradeId: '',
        classId: '',
        lessonId: '',
        teacherId: '',
      },

      classListDialog: [], // 弹窗班级列表

      currentYear: {},

      selectedRows: [],
      filterOptions: {},
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,

        pageChange: this.pageChange,
      },

      teachModal: {
        visible: false,
        title: '',
        rules: {
          periodName: [{ required: true }],
          gradeDefName: [{ required: true }],
          gradeName: [
            { required: true, message: '请输入年级别名', trigger: 'blur' },
            { max: 20, message: '20字符，支持汉字/字母/数字/特殊字符', trigger: 'blur' },
          ],
          seq: [{ required: true, message: '请输入升学年序号', trigger: 'blur' }],
        },
        form: {},
      },
    };
  },

  methods: {
    async saveTeach() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const { gradeName, seq, periodId, gradeId } = this.gradeModal.form;

          let res;

          if (gradeId) {
            res = await editGrade({
              gradeId,
              gradeName,
              seq,
            });
          } else {
            res = await addGrade({
              periodId,
              gradeName,
              seq,
            });
          }

          if (res.status === 0) {
            this.$message.success('操作成功');
            this.gradeModal.visible = false;
            this.getTableData();
          }
        }
      });
    },

    filterChangeHandler(filterObj) {
      // 可选
      this.filterOptions = {
        ...this.filterOptions,
        [Object.keys(filterObj)[0]]:
          Object.values(filterObj)[0].length > 0 ? Object.values(filterObj)[0].join(',') : undefined,
      };
    },

    sortChange({ prop, order }) {
      let name;
      if (prop === 'gradeName') name = 'gradeOrder';
      else if (prop === 'className') name = 'classOrder';
      else if (prop === 'lessonName') name = 'lessonOrder';

      this.filterOptions[name] = {
        ascending: 0,
        descending: 1,
      }[order];

      this.pagination.page = 1;
      this.getTableData();
    },

    pageChange() {
      this.getTableData();
    },

    handleSelectAll() {
      this.getAllTableData();
    },

    async getAllTableData() {
      const res = await getTeachList({
        ...this.filterOptions,
        pageSize: this.pagination.total,
        pageIndex: 1,
      });

      if (res.status === 0) {
        this.selectedRows = res.result.pageHolder.data;
      }
    },

    async getTableData() {
      const res = await getTeachList({
        pageIndex: this.pagination.page,
        pageSize: this.pagination.pageSize,

        ...this.filterOptions,
      });

      if (res.status === 0) {
        this.listData.list = res.result.pageHolder.data;
        this.pagination.total = +res.result.pageHolder.recordCount;
      }
    },

    async getGradeList() {
      const res = await getGradeList({
        status: 0,
      });

      if (res.status === 0) {
        this.gradeList = res.result.map((item) => ({
          text: item.gradeName,
          value: item.gradeId,
          periodId: item.periodId,
        }));
      }
    },

    async getClassList() {
      const res = await getClassList({
        pageSize: 99999,
      });

      if (res.status === 0) {
        this.classList = res.result.data.map((item) => ({
          text: `${item.gradeName}${item.className}`,
          value: item.classId,
        }));
      }
    },

    async getLessonList() {
      const res = await getLessonList({
        status: 0,
      });

      if (res.status === 0) {
        this.lessonList = res.result.map((item) => ({
          label: item.periodName,
          value: item.periodId,
          children:
            item.basicSchLessonDTOList?.map((item) => ({
              label: item.lessonName,
              value: item.lessonId,
            })) || [],
        }));
      }
    },

    // handle 搜索
    handleSearch() {
      this.getClassLessonList();
    },

    getClassLessonList(reset = false) {
      let { pageIndex, pageSize } = this.listData;

      if (reset) pageIndex = this.listData.pageIndex = 1;

      this.loading = true;

      getClassLessonList({
        pageIndex,
        pageSize,
        gradeId: this.gradeId === '-1' ? undefined : this.gradeId,
        classId: this.classId === '-1' ? undefined : this.classId,
        searchKey: this.searchKey,
      })
        .then(
          (res) => {
            let { recordCount, data } = res.result;
            this.listData.total = recordCount;
            this.listData.list = data || [];
          },
          (err) => console.log(err)
        )
        .finally(() => {
          this.loading = false;
        });
    },

    // 年级改变
    async handleGradeChange(id) {
      const classList = await this.getClassListData(id);
      this.classList = [
        {
          classId: '-1',
          className: '全部',
        },
        ...classList,
      ];
      this.classId = '-1';
      this.getClassLessonList(true);
    },

    // 获取年级里的班级
    async getClassListData(id) {
      const res = await getClassList({
        gradeIds: [id],
        pageSize: 99999,
      });

      if (res.status === 0) {
        return res.result.data;
      }
    },

    // 教师列表
    async getTeacherListByUserName() {
      const res = await getTeacherTable({
        pageSize: 9999999,
        pageIndex: 1,
      });

      if (res.status === 0) {
        this.teacherList = res.result.data;
      }
    },

    // 新增任教
    async handleAdd() {
      await this.getTeacherListByUserName();

      this.classListDialog = [];
      this.formData = {
        id: '',
        gradeId: '',
        classId: '',
        lessonId: '',
        teacherId: '',
      };
      this.isEdit = false;
      this.visible = true;
    },

    // 编辑任教
    async handleEdit(item) {
      await this.getTeacherListByUserName();

      this.formData = {
        id: item.classRTeacherId,
        gradeId: item.gradeId,
        classId: item.classId,
        lessonId: item.lessonId,
        teacherId: item.teacherId,
      };

      this.isEdit = true;
      this.visible = true;
    },

    handleDel(row) {
      this.$msgbox
        .confirm('你确定删除任教信息吗？', '提示', {
          customClass: 'ql-message-box_notitle',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          delClassLesson({
            idList: [row.classRTeacherId],
          }).then((res) => {
            if (res.status === 0) {
              this.$message.success('删除成功');
              this.getTableData();
            }
          });
        });
    },

    handleSuccess() {
      this.visible = false;
      this.getTableData();
    },

    handleSizeChange(val) {
      this.listData.pageSize = val;
      this.getClassLessonList(true);
    },
    handleCurrentChange(val) {
      this.listData.pageIndex = val;
      this.getClassLessonList();
    },

    // 批量录入
    handleImport() {
      this.$batchEntry({
        type: 'teach',
        downloadMethod: 'GET',
        callback: () => {
          this.getTableData();
        },
      });
    },

    // 批量导出
    handleExport() {
      this.$batchExport({
        type: 'teach',
        downloadMethod: 'POST',
        data: {
          idList: this.selectedRows.map((item) => item.classRTeacherId),
          ...this.filterOptions,
          gradeIdList: this.filterOptions.gradeIdList?.split(','),
        },
      });
    },

    async getCurrentYear() {
      const res = await getCurrentYear();

      if (res.status === 0) {
        this.currentYear = res.result;
      }
    },

    handleLessonClass(val) {
      this.filterOptions = {
        ...this.filterOptions,
        lessonIdList: val.map((item) => item[1]).join(),
      };
    },
  },

  mounted() {
    this.getCurrentYear();
    this.getGradeList();
    this.getClassList();
    this.getLessonList();
    this.getTableData();
  },

  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },

    notAllowOperate() {
      // return this.userInfo.thirdpartySchoolProjectCode !== '';
      return false;
    },

    columns() {
      return [
        {
          label: '年级',
          prop: 'gradeName',
          columnKey: 'gradeIdList',
          filters: this.gradeList,
          sortable: 'custom',
        },
        {
          label: '班级',
          prop: 'className',
          columnKey: 'classIdList',
          filters: this.classList,
          formatter: (row) => row.className || '-',
        },
        {
          label: '学科',
          prop: 'lessonName',
          columnKey: 'lessonIdList',
          headerProps: () => ({
            placeholder: '学科',
            options: this.lessonList,
            props: {
              multiple: true,
            },
            onChange: this.handleLessonClass,
          }),
          headerComponent: CascaderHeader,
        },
        {
          label: '教师姓名',
          prop: 'teacherName',
        },
        {
          label: '教师账号',
          prop: 'teacherLoginName',
        },
        {
          label: '教职工号',
          prop: 'idNumber',
        },
        {
          label: '操作',
          props: () => ({
            btnList: [
              {
                text: '编辑',
                disabled: () => this.notAllowOperate,
                handle: this.handleEdit,
              },
              {
                text: '删除',
                disabled: () => this.notAllowOperate,
                handle: this.handleDel,
              },
            ],
          }),
          component: OperationBtns,
        },
      ];
    },
  },

  watch: {
    filterOptions: {
      handler() {
        this.pagination.page = 1;
        this.getTableData();
      },
      deep: true,
    },
  },
};
